import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from, lastValueFrom } from "rxjs";
import { ServiceSvExtra } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ServiceSvCategory } from '../01-models/work-order-category';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { PresenceService } from './presence.service';
import { ExpectedPresenceService } from './expected-presence.service';
import { EXPECTED_PRESENCES_KEY, PRESENCES_KEY, SERVICE_SV_EXTRA_KEY } from '../05-shared/utils/Contanst';
const WORK_ORDERS_CATEGORIES_KEY = 'work_categories';

@Injectable({
  providedIn: 'root'
})
export class ServiceSVExtraService {
  http = inject(HttpClient);
  networkService = inject(NetworkService);
  offlineManager = inject(OfflineManagerService);
  toastService = inject(ToastService);
  storageService = inject(StorageService);
  constructor(
  ) { }

  getServiceSvExtra(): Observable<ServiceSvExtra[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/servicessvextra`;
    return this.http.get<ServiceSvExtra[]>(url).pipe(
      map(serviceSvExtras => {
        this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
        return serviceSvExtras;
      })
    );
  }

  getAllServiceSvExtras(): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY));
  }

  getAllServiceSvExtraLocal(): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.filter(serviceSvExtra => serviceSvExtra.serviceType === 'Extra')
        .sort((a, b) => ((new Date(a.date.toString())) < (new Date(b.date.toString())) ? 1 : -1) || a.companyName.localeCompare(b.companyName)))
    );
  }

  getServiceSvExtraById(id: number): Observable<ServiceSvExtra> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.find(serviceSvExtra => serviceSvExtra.id == id))
    );
  }

  getServiceSvExtraByRef(ref: string): Observable<ServiceSvExtra> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.find(serviceSvExtra => serviceSvExtra.serviceSvRef == ref))
    );
  }

  getServiceSvExtraByCompanyId(companyId: number): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.filter(serviceSvExtra => serviceSvExtra.serviceType === 'Extra' && serviceSvExtra.companyId === companyId))
    );
  }

  getServiceSvExtrasByDni(workerDni: string): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.filter(serviceSvExtra => serviceSvExtra.serviceType === 'Extra' && ((serviceSvExtra.teamLeads && serviceSvExtra.teamLeads.indexOf(workerDni) !== -1) || (serviceSvExtra.team && serviceSvExtra.team.indexOf(workerDni) !== -1)))
        .sort((a, b) => ((new Date(a.date.toString())) < (new Date(b.date.toString())) ? 1 : -1) || a.companyName.localeCompare(b.companyName)))
    );
  }

  delete(serviceSvExtraId: number) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', serviceSvExtraId);
    } else {
      this.http.post(url, serviceSvExtraId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', serviceSvExtraId);
        }
      );
    }
    return from(this.deleteServiceSvExtra(serviceSvExtraId));
  }

  deleteServiceSvExtra(serviceSvExtraId: number) {
    return this.storageService.get(SERVICE_SV_EXTRA_KEY).then(serviceSvExtras => {
      const index = serviceSvExtras.findIndex(serviceSvExtra => serviceSvExtra.id === serviceSvExtraId);
      serviceSvExtras.splice(index, 1);
      return this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
    });
  }

  add(serviceSvExtra: ServiceSvExtra): Observable<any> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/add`;
    return this.http.post(url, serviceSvExtra);
  }

  addServiceSvExtra(newServiceSvExtra: ServiceSvExtra): Observable<ServiceSvExtra> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY).then((serviceSvExtras: ServiceSvExtra[]) => {
      if (serviceSvExtras) {
        serviceSvExtras.unshift(newServiceSvExtra);
        return this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
      }
      return this.storageService.set(SERVICE_SV_EXTRA_KEY, [newServiceSvExtra]);
    }));
  }

  update(serviceSvExtra: ServiceSvExtra) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', serviceSvExtra);
    } else {
      this.http.post(url, serviceSvExtra).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', serviceSvExtra);
        }
      );
    }
    return from(this.serviceSvExtraUpdate(serviceSvExtra));
  }

  serviceSvExtraUpdate(serviceSvExtra: ServiceSvExtra) {
    return this.storageService.get(SERVICE_SV_EXTRA_KEY).then((serviceSvExtras: ServiceSvExtra[]) => {
      const index = serviceSvExtras.findIndex(serviceSvExtraItem => serviceSvExtraItem.id === serviceSvExtra.id);
      serviceSvExtras[index] = serviceSvExtra;
      return this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
    });
  }

  getWorkCategories(): Observable<ServiceSvCategory[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/categories`;
    return this.http.get<ServiceSvCategory[]>(url).pipe(
      map(serviceSvExtrasCategories => {
        this.storageService.set(WORK_ORDERS_CATEGORIES_KEY, serviceSvExtrasCategories);
        return serviceSvExtrasCategories;
      })
    );
  }

  getServiceSvExtrasCategoriesFromLocal(): Observable<ServiceSvCategory[]> {
    return from(this.storageService.get(WORK_ORDERS_CATEGORIES_KEY));
  }

  signature(editedServiceSvExtra: ServiceSvExtra) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/signature`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedServiceSvExtra);
    } else {
      this.http.post(url, editedServiceSvExtra).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedServiceSvExtra);
        }
      );
    }
    return from(this.signatureServiceSvExtra(editedServiceSvExtra));
  }

  signatureServiceSvExtra(serviceSvExtra: ServiceSvExtra) {
    return this.storageService.get(SERVICE_SV_EXTRA_KEY).then((serviceSvExtras) => {
      const index = serviceSvExtras.findIndex(serviceSvExtras => serviceSvExtras.id === serviceSvExtra.id);
      serviceSvExtras[index] = serviceSvExtra;
      return this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
    });
  }

  async serviceSvExtraStatusUpdate(serviceSvRef: string, cpdStatusId: string = '00') {
    if (serviceSvRef.includes('CON')) {
      return;
    }
    try {
      const updatedService = await this.serviceSvExtraValidateState(serviceSvRef, cpdStatusId);
      return this.serviceSvExtraUpdate(updatedService).then();
    } catch (error) {
      console.error('Error al actualizar el estado del servicio(SV):', error);
      this.toastService.error('Error al actualizar el estado del servicio(SV).');
    }
  }


  async serviceSvExtraValidateState(ref: string, cpdStatusId: string = '00') {
    try {
      const serviceSvExtra = await lastValueFrom(this.getServiceSvExtraByRef(ref));
      if (!serviceSvExtra) {
        throw new Error(`No se encontró el servicio con referencia: ${ref}`);
      }
      const presences = await lastValueFrom(this.getPresencesByServiceRef(ref));
      const expectedPresences = await lastValueFrom(this.getExpectedPresencesByServiceRef(ref));

      const totalHoursPresences = presences.reduce((acc, presence) => acc + presence.workedHours, 0);
      const totalHoursExpectedPresences = expectedPresences.reduce((acc, presence) => acc + presence.expectedHours, 0);

      const cpdNum = serviceSvExtra.cpdNum;

      if (cpdStatusId !== '00') {
        serviceSvExtra.serviceSVStatusId = serviceSvExtra.cpdStatusId;
        serviceSvExtra.cpdStatusId = cpdStatusId;
      } else {
        switch (true) {
          case totalHoursPresences < totalHoursExpectedPresences:
            serviceSvExtra.serviceSVStatusId = '02';
            break;
          case totalHoursPresences > 0 && totalHoursExpectedPresences === 0:
            serviceSvExtra.serviceSVStatusId = '03';
            break;
          case totalHoursPresences > 0 && totalHoursExpectedPresences === totalHoursPresences:
            serviceSvExtra.serviceSVStatusId = '03';
            break;
          default:
            serviceSvExtra.serviceSVStatusId = '01';
            break;
        }
      }
      return serviceSvExtra;
    } catch (error) {
      console.error('Error en serviceSvValidateState:', error);
      throw error;
    }
  }

  getPresencesByServiceRef(serviceSvRef: string) {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.serviceSvRef == serviceSvRef))
    );
  }

  getExpectedPresencesByServiceRef(serviceSvRef: string) {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.serviceSvRef == serviceSvRef))
    );
  }

  serviceSvExtraCloseByAdministration(RefSVCPR: string) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/ServicioExtraCerrarPorAdministracion`;
    return this.http.post(url, {RefSVCPR});
  }
}
