import { inject, Injectable } from '@angular/core';
import { API_URL, VERSION } from "../../environments/environment";
import { BehaviorSubject, Observable, from, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ExpectedPresence, Presence, User } from '../01-models';
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { finalize, map } from "rxjs/operators";
import { format, parseISO, addDays, differenceInDays, isBefore, differenceInMilliseconds, intervalToDuration, isSameDay, isAfter } from 'date-fns';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { ServiceSVExtraService } from './service-sv-extra.service';
import { PRESENCES_KEY } from '../05-shared/utils/Contanst';
import { Device } from '@capacitor/device';
import { AuthService } from './auth.service';
import { ExpectedPresenceService } from './expected-presence.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {

  updateListPresence = new BehaviorSubject<boolean>(false);
  user: User;

  http = inject(HttpClient);
  networkService = inject(NetworkService);
  offlineManager = inject(OfflineManagerService);
  toastService = inject(ToastService);
  storageService = inject(StorageService);
  serviceSvService = inject(ServiceSVExtraService);
  authService = inject(AuthService);
  alertController = inject(AlertController);
  expectedPresenceService = inject(ExpectedPresenceService);

  constructor(
  ) { 
    setTimeout(() => {
      this.user = this.authService.getUser();
    }, 1000);
  }

  scan(newPresence) {
    const url = `${API_URL}/${VERSION}/presence/scan`;

    if (newPresence.expectedPresenceId === null && newPresence.expectedPresenceTmpId === null) {
      this.toastService.error('Error idPrevisión no existe');
      throw new Error('Error expectedPresenceId no existe ' + JSON.stringify(newPresence));
    }

    const networkStatus = this.networkService.getCurrentNetworkStatus();
    if (networkStatus == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', newPresence);
    } else {
      this.http.post(url, newPresence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', newPresence);
        }
      );
    }
    return from(this.addPresence(newPresence));
  }

  addPresence(newPresence: Presence) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      let saved = null
      if (presences) {
        presences.unshift(newPresence);
        saved = await this.storageService.set(PRESENCES_KEY, presences);
      } else {
        saved = await this.storageService.set(PRESENCES_KEY, [newPresence]);
      }
      await this.serviceSvService.serviceSvExtraStatusUpdate(newPresence.serviceSvRef);
      return saved;
    });
  }

  update(presence) {
    const url = `${API_URL}/${VERSION}/presence/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', presence);
    } else {
      this.http.post(url, presence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', presence);
        }
      );
    }
    return from(this.updatePresence(presence));
  }

  updatePresence(editedPresence: Presence) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = presences.findIndex(presence => presence.tmpId === editedPresence.tmpId);
      presences[index] = editedPresence;
      const presence = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.serviceSvExtraStatusUpdate(editedPresence.serviceSvRef);
      return presence;
    });
  }

  getPresences(): Observable<Presence[]> {
    const url = `${API_URL}/${VERSION}/presence/presences`;

    // Obtener fecha de modificacion del ultimo registro
    //const data = this.storageService.get(PRESENCES_KEY).then((presences: any[]) => {

    return this.http.get<Presence[]>(url).pipe(
      map(presences => {
        this.storageService.set(PRESENCES_KEY, presences);
        return presences;
      })
    );
  }

  getAllPresences(): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY));
  }

  getPresencesByUserId(userId: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.userId == userId))
    );
  }

  getAllPresencesInprogress(): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => {
        if (presences) {
          return presences
            .filter(presence => !presence.dateTimePresenceEnd)
            .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
        }
      })
    );
  }

  getPresencesInprogressByUserId(userId: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => {
        if (presences) {
          return presences
          .filter(presence => presence.userId == userId && !presence.dateTimePresenceEnd)
          .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) > (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
        }
      }
      )
    );
  }

  getPresencesInprogressByCompanyIdAndworkplaceNum(companyId: number, workplaceNum: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.companyId == companyId && presence.workplaceNum == workplaceNum && !presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) > (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesInprogressByServiceId(serviceId: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.serviceId === serviceId && !presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) > (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesInprogressByServiceRef(serviceSvRef: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.serviceSvRef === serviceSvRef && !presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) > (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getAllPresencesDone(): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => {
        if (presences) {
          return presences
            .filter(presence => presence.dateTimePresenceEnd)
            .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
        }
      })
    );
  }

  getPresencesDoneByUserId(userId: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => {
        if (presences) {
          return presences
          .filter(presence => presence.userId == userId && presence.dateTimePresenceEnd)
          .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
        }
      }
      )
    );
  }

  getPresencesDoneByDni(workerDni: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.workerDni == workerDni && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesDoneByCompanyIdAndworkplaceNum(companyId: number, workplaceNum: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.companyId == companyId && presence.workplaceNum == workplaceNum && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesDoneByServiceId(serviceId: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.serviceId == serviceId && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesDoneByServiceRef(serviceSvRef: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.serviceSvRef == serviceSvRef && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesByServiceRef(serviceSvRef: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.serviceId == serviceSvRef))
    );
  }

  getPresenceById(id: number): Observable<Presence> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.find(presence => presence.id == id))
    );
  }

  getPresenceByTmpId(tmpId: string): Observable<Presence> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.find(presence => presence.tmpId == tmpId))
    );
  }

  delete(presence, tmpId) {
    const url = `${API_URL}/${VERSION}/presence/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', presence);
    } else {
      this.http.post(url, presence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', presence);
        }
      );
    }
    return from(this.deletePresence(presence))
  }

  deletePresence(presence) {
    return this.storageService.get(PRESENCES_KEY).then(async presences => {
      const index = presence ? presences.findIndex(p => p.id === presence.id) : presences.findIndex(p => p.tmpId === presence.tmpId);
      presences.splice(index, 1);
      const deleted = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.serviceSvExtraStatusUpdate(presences[index].serviceSvRef);
      return deleted;
    });
  }

  updateExit(id: number, tmpId: string, dateTimePresenceEnd: Date) {
    const url = `${API_URL}/${VERSION}/presence/updateExit`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, dateTimePresenceEnd });
    } else {
      this.http.post(url, { id: id ? id : tmpId, dateTimePresenceEnd }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, dateTimePresenceEnd });
        }
      );
    }
    return from(this.updateExitPresence(id, tmpId, dateTimePresenceEnd))
  }

  updateExitPresence(id: number, tmpId: string, dateTimePresenceEnd: Date) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = id ? presences.findIndex(presence => presence.id === id) : presences.findIndex(presence => presence.tmpId === tmpId);
      presences[index].dateTimePresenceEnd = dateTimePresenceEnd;
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.serviceSvExtraStatusUpdate(presences[index].serviceSvRef);
      return updated;
    });
  }

  updateDates(id: number, tmpId: string, workedHours: number, dateTimePresenceStart: Date, dateTimePresenceEnd: Date, category: string, registeredHours: number) {
    const url = `${API_URL}/${VERSION}/presence/updateDates`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, workedHours, dateTimePresenceStart, dateTimePresenceEnd });
    } else {
      this.http.post(url, { id: id ? id : tmpId, workedHours, dateTimePresenceStart, dateTimePresenceEnd, category }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, workedHours, dateTimePresenceStart, dateTimePresenceEnd });
        }
      );
    }
    return from(this.updateDatesPresence(id, tmpId, workedHours, dateTimePresenceStart, dateTimePresenceEnd, category, registeredHours));
  }

  updateDatesPresence(id: number, tmpId: string, workedHours: number, dateTimePresenceStart: Date, dateTimePresenceEnd: Date, category: string, registeredHours: number) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = id ? presences.findIndex(presence => presence.id === id) : presences.findIndex(presence => presence.tmpId === tmpId);
      presences[index].workedHours = workedHours;
      presences[index].registeredHours = registeredHours;
      presences[index].dateTimePresenceStart = dateTimePresenceStart;
      presences[index].dateTimePresenceEnd = dateTimePresenceEnd;
      presences[index].category = category;
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.serviceSvExtraStatusUpdate(presences[index].serviceSvRef);
      return updated;
    });
  }

  updateStatus(id: number, tmpId: string, statusId: string) {
    const url = `${API_URL}/${VERSION}/presence/updateStatus`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, statusId });
    } else {
      this.http.post(url, { id: id ? id.toString() : tmpId, statusId }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, statusId });
        }
      );
    }
    return from(this.updateStatusPresence(id, tmpId, statusId));
  }

  updateStatusPresence(id: number, tmpId: string, statusId: string) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = id ? presences.findIndex(presence => presence.id === id) : presences.findIndex(presence => presence.tmpId === tmpId);
      presences[index].statusId = statusId;
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.serviceSvExtraStatusUpdate(presences[index].serviceSvRef);
      return updated;
    });
  }

  updateCpdNumByService(serviceSvRef: string, cpdNum: number) {
    const url = `${API_URL}/${VERSION}/presence/updateCpdNumByService`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { serviceId: serviceSvRef, cpdNum });
    } else {
      this.http.post(url, { serviceId: serviceSvRef, cpdNum }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { serviceId: serviceSvRef, cpdNum });
        }
      );
    }
    return from(this.updateCpdNumPresencesByService(serviceSvRef, cpdNum));
  }

  updateCpdNumPresencesByService(serviceId: string, cpdNum: number) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = presences.findIndex(presence => presence.serviceId === serviceId);
      const serviceRef = presences[index].serviceSvRef;
      presences.filter(presence => presence.serviceId === serviceId).forEach(presence => {
        presence.cpdNum = cpdNum;
        presence.cpdStatusId = '03';
      });
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.serviceSvExtraStatusUpdate(serviceRef);
      return updated;
    });
  }

  updateCpdStatusIdByService(serviceId: string, cpdStatusId: string) {
    const url = `${API_URL}/${VERSION}/presence/updateCpdStatusIdByService`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { serviceId, cpdStatusId });
    } else {
      this.http.post(url, { serviceId, cpdStatusId }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { serviceId, cpdStatusId });
        }
      );
    }
    return from(this.updateCpdStatusIdPresencesByService(serviceId, cpdStatusId));
  }

  updateCpdStatusIdPresencesByService(serviceId: string, cpdStatusId: string) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: Presence[]) => {
      const index = presences.findIndex(presence => presence.serviceId === serviceId);
      const serviceRef = presences[index].serviceSvRef;
      
      presences.filter(presence => presence.serviceId === serviceId).forEach(presence => {
        presence.cpdStatusId = cpdStatusId;
        presence.statusId = cpdStatusId;
      });
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.serviceSvExtraStatusUpdate(serviceRef, cpdStatusId);
      return updated;
    });
  }

  async presenceAdd(params: {
    expectedPresence: ExpectedPresence, 
    method: string,
    dateTimePresenceStart: Date, 
    dateTimePresenceEnd?: Date,
    registeredHours: number,
    workedHours: number,
    statusId?: string
}) {
    // Obtener el ID del dispositivo
    const device = await Device.getId();

    if (!this.user) {
      this.user = this.authService.getUser();
    }
    
    // Crear un objeto Presence con la información proporcionada en params
    const presence: Presence = {
      id: null,
      tmpId: `tmp-${params.expectedPresence.workerId}-${new Date().getTime()}`, // Generar ID temporal
      
      userId: params.expectedPresence.workerId, // ID del trabajador
      workerDni: params.expectedPresence.workerDni, // DNI del trabajador
      userName: params.expectedPresence.workerName, // Nombre del trabajador
      workerCode: params.expectedPresence.userCode, // Código del trabajador
      serviceSvRef: params.expectedPresence.serviceSvRef, // Referencia del servicio

      // Horarios de inicio y fin de la presencia
      dateTimePresenceStart: params.dateTimePresenceStart,
      dateTimePresenceEnd: params.dateTimePresenceEnd,

      // Estado de la presencia
      statusId: params.statusId,
      category: null, // Sin categoría definida
      
      expectedPresenceId: params.expectedPresence.id, // ID de la presencia esperada
      expectedPresenceTmpId: params.expectedPresence.expectedPresenceTmpId, // ID temporal de la presencia esperada

      expectedHours: params.expectedPresence.expectedHours, // Horas esperadas
      registeredHours: params.registeredHours, // Horas registradas
      workedHours: params.workedHours, // Horas trabajadas

      method: params.method, // Método utilizado para registrar la presencia
      latitude: null,
      longitude: null,
      deviceId: device.identifier, // ID del dispositivo
      
      // Información sobre la compañía y el lugar de trabajo
      companyId: params.expectedPresence.companyId,
      companyName: params.expectedPresence.companyName,
      workplaceNum: params.expectedPresence.workplaceNum,
      workplaceName: params.expectedPresence.workplaceName,
      workStation: params.expectedPresence.workStation || 1,

      // Información adicional sobre el servicio
      tagId: null,
      serviceId: params.expectedPresence.serviceId || '0',
      serviceDescription: params.expectedPresence.serviceDescription,
      numLinea: params.expectedPresence.lineNum,
      numeroServicio: params.expectedPresence.serviceNum,
      horaTipoCodigo: params.expectedPresence.hourType,
      horarioNum: params.expectedPresence.horarioNum,
      subActividad: params.expectedPresence.subActivity,
      presenceType: params.expectedPresence.presenceType, // Tipo de presencia
      
      // Información sobre la creación y modificación del registro
      REGCREACION: `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}/KFLOW\\${this.user.id}-${this.user.userName}`,
      REGULTMOD: `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}/KFLOW\\${this.user.id}-${this.user.userName}`,
    };

    // Verificar si hay solapamiento con otras presencias
    const presenceOverlapping = await this.presenceIsOverlapping(
      params.expectedPresence.workerId, // ID del trabajador
      params.dateTimePresenceStart, // Fecha de inicio de la presencia
      params.dateTimePresenceEnd || new Date() // Fecha de fin, si no se proporciona, se usa la fecha actual
    );

    // Si hay solapamiento, devolver un error
    if (presenceOverlapping) {
      return { error: 'La presencia se solapa con otra presencia', presence: presenceOverlapping };
    }

    // Escanear y registrar la nueva presencia
    await lastValueFrom(this.scan(presence));
    
    // Actualizar el estado de la presencia esperada
    await lastValueFrom(this.expectedPresenceService.expectedPresenceStatusUpdate(
      params.expectedPresence.id, 
      params.expectedPresence.expectedPresenceTmpId, 
      params.statusId
    ));
    
    // Devolver el resultado exitoso
    return { error: null, presence };
  }

  async presentAlertOverlaping(presence: Presence) {
    const dateTimePresenceStart = format(new Date(presence.dateTimePresenceStart), 'dd-MM-yyyy HH:mm');
    const dateTimePresenceEnd = format(new Date(presence.dateTimePresenceEnd), 'dd-MM-yyyy HH:mm');
    const message = `<p>Presencia de Operario Solapada con:</p>
      <p>ServicioSV ${presence.serviceSvRef}</p>
      <p>Fecha Inicio ${dateTimePresenceStart}</p>
      <p>Fecha Fin ${dateTimePresenceEnd}</p>
      <p>Empresa ${presence.companyName}</p>`;

    const alert = await this.alertController.create({
      header: 'Presencia Solapada',
      message: message,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

/**
 * @param dateTimePresenceStart 
 * @param dateTimePresenceEnd 
 * @returns Presencia que se solapa con la nueva presencia
 */
async presenceIsOverlapping(
  userId: number, // ID del trabajador
  dateTimePresenceStart: Date, // Fecha y hora de inicio de la nueva presencia
  dateTimePresenceEnd: Date // Fecha y hora de fin de la nueva presencia
) {
    // Función para verificar si dos rangos de fechas se solapan
    const areDatesOverlapping = (
      firstStartDate: Date, // Inicio del primer rango
      firstEndDate: Date,   // Fin del primer rango
      secondStartDate: Date, // Inicio del segundo rango
      secondEndDate: Date    // Fin del segundo rango
    ) => {
      // La condición para solapamiento: el inicio del primer rango es anterior al fin del segundo
      // y el inicio del segundo rango es anterior al fin del primero
      return firstStartDate < secondEndDate && secondStartDate < firstEndDate;
    };

    // Obtener todas las presencias registradas para el trabajador
    const registeredPresences = await lastValueFrom(this.getPresencesByUserId(userId));

    // Filtrar presencias que se solapen con la nueva
    const overlappingPresences = registeredPresences.filter((presence) => {
      const registeredStartDate = new Date(presence.dateTimePresenceStart); // Fecha de inicio registrada
      const registeredEndDate = new Date(presence.dateTimePresenceEnd); // Fecha de fin registrada

      // Verificar si ambas presencias están en el mismo día y si los horarios se solapan
      return (
        registeredStartDate.toDateString() === dateTimePresenceStart.toDateString() && // Mismo día
        areDatesOverlapping(
          dateTimePresenceStart, 
          dateTimePresenceEnd, 
          registeredStartDate, 
          registeredEndDate
        )
      );
    });

    // Retornar la primera presencia solapada o null si no hay solapamiento
    return overlappingPresences[0] || null;
}


  // Método que reutilizarás en los componentes
  isOutOfDate(startTimeString: string): boolean {
    const now = new Date();
    const startTime = new Date(startTimeString);
    
    // Añadir 12 horas a la startTime
    startTime.setHours(startTime.getHours() + 12);
    
    // Registro para verificar los valores
    //console.log(`Comparando: startTime ajustada: ${startTime}, now: ${now}`);
    
    return startTime > now;
  }

  isOutOfDateExpectedPresence(presence: ExpectedPresence): boolean { // startTime 01/10/2024 01:00:00
    // El mismo día son 12 horas de diferencia para deternimar si es antes de hoy
    const now = new Date(); // 01/10/2024 14:19:00
    const startTime = new Date(presence.startTime.toString());
    
    // Se restan 12 horas a la startTime
    startTime.setHours(startTime.getHours() + 12);// startTime 01/10/2024 13:00:00
    
    // Registro para verificar los valores
    //console.log(`Comparando: startTime ajustada: ${startTime}, now: ${now}`);
    
    return startTime < now; // startTime 01/10/2024 13:00:00 < now 01/10/2024 14:19:00 = true
  }

  isTodayPresence(presence: ExpectedPresence): boolean {
    const startTime = format(parseISO(presence.startTime.toString()), 'dd-MM-yyyy');
    const today = format(new Date(), 'dd-MM-yyyy');
    return startTime === today;
  }

  isAfterTodayPresence(presence: ExpectedPresence): boolean {
    const startTime = parseISO(presence.startTime.toString());
    const today = new Date();
    return isAfter(startTime, today.setHours(23, 59, 59, 999));
  }

}
