import { inject, Injectable } from '@angular/core';
import { Beacon } from '../01-models/beacon';
import { ExtendedScanResult } from '../04-pages/beacons/beacons.page';
import { Bluetooth } from '../01-models/bluetooth';
import { StorageService } from './storage.service';
import { Wifi } from '../01-models/wifi';
import { Geolocation } from '../01-models/geolocation';
import { Nfc } from '../01-models/nfc';
import { API_URL, VERSION } from 'src/environments/environment';
import { ConnectionStatus, NetworkService } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
/**
 * @description
 * Este servicio cuenta maneja 4 tipos de beacons, cada uno con sus propias características.
 * Se van a manejar con CRUDs para ser gestionados por cada workplace.
 */
export class BeaconsService {

  storageService = inject(StorageService);
  private networkService = inject(NetworkService);
  private offlineManager = inject(OfflineManagerService);
  private http = inject(HttpClient);
  private toastService = inject(ToastService);
  constructor() { }

  //#region Bluetooth Beacons
  async getListBluetoothBeaconByWorkplaces(workplaceId: string): Promise<Bluetooth[]> {
    const devices = await this.storageService.get('beacons-bluetooth') as Bluetooth[];
    return devices ? devices.filter(device => device.workplace === workplaceId) : [];
  }

  async addBluetoothBeacon(beacon: Bluetooth[]): Promise<void> {
    let devices = await this.storageService.get('beacons-bluetooth') as Bluetooth[];
    if (!devices) {
      devices = [];
    }
    beacon.forEach(b => {
      b.beaconTmpId = Math.random().toString(36).substring(2);
      devices.push(b);
    });

    try {
      await this.addBluetoothRequest(beacon);
    } catch (error) {
      console.log(error);
    }

    return await this.storageService.set('beacons-bluetooth', devices);

  }

  async updateBluetoothBeacon(beacon: Bluetooth): Promise<void> {
    const devices = await this.storageService.get('beacons-bluetooth') as Bluetooth[];
    const index = devices.findIndex(device => device.beaconTmpId === beacon.beaconTmpId);
    devices[index] = beacon;
    await this.storageService.set('beacons-bluetooth', devices);
  }

  async deleteBluetoothBeacon(beacon: Bluetooth): Promise<Bluetooth[]> {
    const devices = await this.storageService.get('beacons-bluetooth') as Bluetooth[];
    console.log("worplaces:", beacon.workplace);
    const index = devices.findIndex(device => device.workplace === beacon.workplace && device.uuidbbl === beacon.uuidbbl);

    // Verificar si el dispositivo fue encontrado antes de eliminar
    if (index !== -1) {
      devices.splice(index, 1);
      console.log(`Beacon eliminado: ${beacon.uuidbbl}`);
    } else {
      console.log(`Beacon no encontrado: ${beacon.uuidbbl}`);
    }
    this.deleteBluetoothRequest(beacon);
    await this.storageService.set('beacons-bluetooth', devices);

    return devices;
  }
  //#endregion

  //#region Bluetooth Peticiones

  addBluetoothRequest(bluetooth: Bluetooth[]): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/addBluetooth`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', bluetooth);
    } else {
      // Envía la solicitud al servidor y espera que devuelva los elementos con los Ids actualizados
      return lastValueFrom(this.http.post<Bluetooth[]>(url, bluetooth)).then(
        (response) => {
          // Itera sobre la respuesta y actualiza cada objeto `Bluetooth` en la lista original
          response.forEach((updatedBluetooth, index) => {
            bluetooth[index].id = updatedBluetooth.id;
          });
          console.log("Bluetooth devices updated with new IDs:", bluetooth);
        },
        (error) => {
          console.error("Error adding Bluetooth devices:", error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', bluetooth); // Guarda la solicitud para procesarla después si falla
        }
      );
    }
  }

  deleteBluetoothRequest(bluetooth: Bluetooth): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/deleteBluetooth`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', bluetooth);
    } else {
      return lastValueFrom(this.http.post(url, bluetooth)).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', bluetooth);
        }
      );
    }
  }
  //#endregion

  //#region WiFi Beacons
  async getListWifiBeaconByWorkplaces(workplaceId: string): Promise<Wifi[]> {
    const devices = await this.storageService.get('beacons-wifi') as Wifi[];
    return devices ? devices.filter(device => parseInt(device.workplace) === parseInt(workplaceId)) : [];
  }

  async getAllWifiBeacons(): Promise<Wifi[]> {
    await this.getAllWifiRequest();
    return this.storageService.get('beacons-wifi') as Promise<Wifi[]>;
  }

  async addWifiBeacon(beacon: Wifi): Promise<void> {
    let devices = await this.storageService.get('beacons-wifi') as Wifi[];
    if (!devices) {
      devices = [];
    }
    beacon.beaconTmpId = Math.random().toString(36).substring(2);
    const index = devices.findIndex(device => device.ssidbwi === beacon.ssidbwi);
    if (index === -1) {
      devices.push(beacon);
    } else {
      console.log('Ya existe un dispositivo con ese nombre');
    }
    try {
      await this.addWifiRequest(beacon);
    } catch (error) {
      console.log(error);
    }
    return await this.storageService.set('beacons-wifi', devices);

  }

  async updateWifiBeacon(beacon: Wifi): Promise<void> {
    const devices = await this.storageService.get('beacons-wifi') as Wifi[];
    const index = devices.findIndex(device => device.beaconTmpId === beacon.beaconTmpId);
    devices[index] = beacon;
    await this.storageService.set('beacons-wifi', devices);
  }

  async deleteWifiBeacon(wifi: Wifi): Promise<void> {
    const devices = await this.storageService.get('beacons-wifi') as Wifi[];
    const index = devices.findIndex(device => device.ssidbwi === wifi.ssidbwi);
    devices.splice(index, 1);
    await this.storageService.set('beacons-wifi', devices);
    await this.deleteWifiRequest(wifi);

  }

  //#endregion

  //#region WIFI Peticiones

  getAllWifiRequest(): Promise<Wifi[]> {
    const url = `${API_URL}/${VERSION}/Beacon/wifi`;
    return lastValueFrom(this.http.get<Wifi[]>(url)).then(
      response => {
        this.storageService.set('beacons-wifi', response);
        return response;
      },
      error => {
        this.toastService.error(error.message);
        throw error;
      }
    );
  }

  addWifiRequest(wifi: Wifi): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/addWifi`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', wifi);
    } else {
      return lastValueFrom(this.http.post(url, wifi)).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', wifi);
        }
      );
    }
  }

  deleteWifiRequest(wifi: Wifi): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/deleteWifi`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', wifi);
    } else {
      return lastValueFrom(this.http.post(url, wifi)).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', wifi);
        }
      );
    }
  }
  //#endregion

  //#region GPS Beacons
  async getListGeolocationBeaconByWorkplaces(workplaceId: string): Promise<Geolocation[]> {
    const devices = await this.storageService.get('beacons-gps') as Geolocation[];
    return devices ? devices.filter(device => device.workplace === workplaceId) : [];
  }

  async addGPSBeacon(beacon: Geolocation[]): Promise<void> {
    let gpsPoints = await this.storageService.get('beacons-gps') as Geolocation[];
    if (!gpsPoints) {
      gpsPoints = [];
    }
    beacon.forEach(b => {
      b.beaconTmpId = Math.random().toString(36).substring(2);
      gpsPoints.push(b);
    });

    try {
      await this.addGeolocationRequest(gpsPoints);
    } catch (error) {
      console.log(error);
    }

    return await this.storageService.set('beacons-gps', gpsPoints);

  }

  async updateGPSBeacon(beacon: Geolocation): Promise<void> {
    const devices = await this.storageService.get('beacons-gps') as Geolocation[];
    const index = devices.findIndex(device => device.beaconTmpId === beacon.beaconTmpId);
    devices[index] = beacon;
    await this.storageService.set('beacons-gps', devices);
  }

  async deleteGpsBeacon(beacon: Geolocation): Promise<Geolocation[]> {
    const devices = await this.storageService.get('beacons-gps') as Geolocation[];
    console.log("worplaces:", beacon.workplace);
    const index = devices.findIndex(device => device.workplace === beacon.workplace && device.id === beacon.id);

    // Verificar si el dispositivo fue encontrado antes de eliminar
    if (index !== -1) {
      devices.splice(index, 1);
      console.log(`Beacon eliminado: ${beacon.id}`);
    } else {
      console.log(`Beacon no encontrado: ${beacon.id}`);
    }
    this.deleteGeolocationRequest(beacon);
    await this.storageService.set('beacons-gps', devices);

    return devices;
  }
  //#endregion

  //#region GPS Peticiones

  addGeolocationRequest(gpsPoints: Geolocation[]): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/addGeolocation`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', gpsPoints);
    } else {
      // Envía la solicitud al servidor y espera que devuelva los elementos con los Ids actualizados
      return lastValueFrom(this.http.post<Geolocation[]>(url, gpsPoints)).then(
        (response) => {
          // Itera sobre la respuesta y actualiza cada objeto `Geolocation` en la lista original
          response.forEach((updatedGpsPoint, index) => {
            gpsPoints[index].id = updatedGpsPoint.id;
          });
          console.log("Geolocation points updated with new IDs:", gpsPoints);
        },
        (error) => {
          console.error("Error adding GPS points:", error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', gpsPoints); // Guarda la solicitud para procesarla después si falla
        }
      );
    }
  }

  deleteGeolocationRequest(gpsPoint: Geolocation): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/deleteGeolocation`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', gpsPoint);
    } else {
      return lastValueFrom(this.http.post(url, gpsPoint)).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', gpsPoint);
        }
      );
    }
  }
  //#endregion

  //#region NFC Beacons

  async getListNFCBeaconByWorkplaces(workplaceId: string): Promise<Nfc[]> {
    const devices = await this.storageService.get('beacons-nfc') as Nfc[];
    return devices ? devices.filter(device => device.workplace === workplaceId) : [];
  }

  async addNFCBeacon(beacon: Nfc): Promise<void> {
    let devices = await this.storageService.get('beacons-nfc') as Nfc[];
    if (!devices) {
      devices = [];
    }
    beacon.beaconTmpId = Math.random().toString(36).substring(2);
    devices.push(beacon);
    await this.storageService.set('beacons-nfc', devices);
    try {
      await this.addNfcRequest(beacon);
    } catch (error) {
      console.log(error);
    }
  }

  async updateNFCBeacon(beacon: Nfc): Promise<void> {
    const devices = await this.storageService.get('beacons-nfc') as Nfc[];
    const index = devices.findIndex(device => device.beaconTmpId === beacon.beaconTmpId);
    devices[index] = beacon;
    await this.storageService.set('beacons-nfc', devices);
    try {
      await this.updateNFCRequest(beacon);
    } catch (error) {
      console.log(error);
    }
  }

  async deleteNFCBeacon(nfc: Nfc): Promise<void> {
    const devices = await this.storageService.get('beacons-nfc') as Nfc[];
    const index = devices.findIndex(device => device.data === nfc.data);
    devices.splice(index, 1);
    await this.storageService.set('beacons-nfc', devices);
    try {
      await this.deleteNfcRequest(nfc);
    } catch (error) {
      console.log(error);
    }
  }
  //#endregion

  //#region Peticiones NFC

  addNfcRequest(nfc: Nfc): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/addNfc`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', nfc);
    } else {
      return lastValueFrom(this.http.post(url, nfc)).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', nfc);
        }
      );
    }
  }

  updateNFCRequest(nfc: Nfc): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/updateNfc`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', nfc);
    } else {
      return lastValueFrom(this.http.post(url, nfc)).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', nfc);
        }
      );
    }
  }

  deleteNfcRequest(nfc: Nfc): Promise<void> {
    const url = `${API_URL}/${VERSION}/Beacon/deleteNfc`;
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', nfc);
    } else {
      return lastValueFrom(this.http.post(url, nfc)).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', nfc);
        }
      );
    }
  }

  //#endregion

  async uploadBluetoothBeacons(bluetoothDevices: Bluetooth[]) {
    const url = `${API_URL}/${VERSION}/beacons/addBluetoothList`;
    return this.uploadBeacons(url, bluetoothDevices, "Bluetooth");
  }

  async uploadWifiBeacons(wifiDevices: Wifi[]) {
    const url = `${API_URL}/${VERSION}/beacons/addWifiList`;
    return this.uploadBeacons(url, wifiDevices, "WiFi");
  }

  async uploadGeolocationBeacons(geolocationDevices: Geolocation[]) {
    const url = `${API_URL}/${VERSION}/beacons/addGeolocationList`;
    return this.uploadBeacons(url, geolocationDevices, "Geolocation");
  }

  async uploadNfcBeacons(nfcDevices: Nfc[]) {
    const url = `${API_URL}/${VERSION}/beacons/uploadNfc`;
    return this.uploadBeacons(url, nfcDevices, "NFC");
  }

  // Función general para realizar la solicitud y manejar errores
  private async uploadBeacons(url: string, devices: any[], type: string) {
    try {
      const response = await firstValueFrom(this.http.post(url, devices));
      this.toastService.present(`${type} beacons subidos correctamente.`);
      return response;
    } catch (error: any) {
      console.error(`Error al subir los beacons ${type}:`, error);
      this.toastService.error(`Error al subir los beacons ${type}: ${error.message}`);
      throw error;
    }
  }
}
