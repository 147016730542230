import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, from, of, forkJoin, concat, throwError } from 'rxjs';
import { switchMap, finalize, concatMap, toArray, delay, catchError, map, retryWhen, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
 
export const STORAGE_REQ_KEY = 'storedreq';
 
interface StoredRequest {
  url: string,
  type: string,
  data: any,
  time: number,
  id: string
}

interface CheckForEventsParams {
    forceSync: boolean;
    showError: boolean;
  }
 
@Injectable({
  providedIn: 'root'
})
export class OfflineManagerService {
 
  constructor(private http: HttpClient, private toastService: ToastService, private storageService: StorageService) { }
 
  checkForEvents({forceSync, showError}:CheckForEventsParams): Observable<any> {
    return from(this.storageService.get(STORAGE_REQ_KEY)).pipe(
      switchMap(operations => {
        if (operations && operations.length > 0) {
          return this.sendRequests(operations).pipe(
            map(() => {
              operations.shift();
            }),
            catchError(error => {
              if (showError) {
                this.toastService.error(error.message);
              }
              throw error.message;
            }),
            finalize(() => {
              if (forceSync) {
                this.storageService.storedreqSubject.next(null);
                this.removeOperations();
              } else {
                this.storeFailedOperations(operations);
              }
            })
          );
        } else {
          return of(false);
        }
      })
    )
  }

  storeFailedOperations(operations: any) {
    this.storageService.set(STORAGE_REQ_KEY, operations);
  }

  removeOperations() {
    this.storageService.remove(STORAGE_REQ_KEY);
  }
 
  storeRequest(url: any, type: any, data: any) {

    let action: StoredRequest = {
      url: url,
      type: type,
      data: data,
      time: new Date().getTime(),
      id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
    };
    // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 
    return this.storageService.get(STORAGE_REQ_KEY).then((storedObj: StoredRequest[]) => {
      if (storedObj) {
        storedObj.push(action);
      } else {
        storedObj = [action];
      }
      // Save old & new local transactions back to Storage
      return this.storageService.set(STORAGE_REQ_KEY, storedObj);
    });
  }
 
  sendRequests(operations: StoredRequest[]) {
    let obs = [];
 
    for (let op of operations) {
      let oneObs = this.http.request(op.type, op.url, {body: op.data});
      obs.push(oneObs);
    }
 
    // Send out all local events and return once they are finished
    return concat(...obs);
    //return forkJoin(obs);
  }
}
